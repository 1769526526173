<template>
  <header class="w-100 shadow">
    <div class="container">
      <div class="row justify-content-between align-items-center h-100">
        <div class="col-4 col-sm-4 col-sm-auto">
          <router-link to="/">
            <img height="25" class="logo" :src="require('@/assets/images/gogogiving.svg')" />
          </router-link>
        </div>
        <div class="col-8 col-sm-8 col-lg-auto d-flex align-items-center text-end">
          <div class="share-items ms-auto me-md-3 h-100">
            <span class="fab fa-facebook-f default-gradient" @click="shareFacebook"></span>
            <a :href="mailtoUrl">
              <span class="far fa-envelope default-gradient"></span>
            </a>
            <span class="fab fa-twitter default-gradient" @click="shareTwitter"></span>
            <span class="fab fa-linkedin default-gradient" @click="shareLinkedin"></span>
            <span class="fab fa-whatsapp default-gradient" @click="shareWhatsApp"></span>
          </div>

          <div class="language-switch">
            <div class="flag-wrapper">
              <img :src="require(`@/assets/images/flags/${$i18n.locale}.png`)" class="current" />
            </div>
            <div class="language-dropdown">
              <span v-for="language in languages" :key="language" @click="changeLanguage(language)">
                <div v-if="language !== $i18n.locale" class="flag-wrapper">
                  <img :src="require(`@/assets/images/flags/${language}.png`)" />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import JQuery from "jquery";

export default {
  name: "PageHader",
  emits: ["languageChanged"],

  data() {
    return {
      locale: "",
      languages: [
        "nl",
        "en",
        "de"
      ]
    };
  },

  computed: {
    mailtoUrl() {
      const subject = this.$t("share.email.subject", { url: window.location.href });
      const message = this.$t("share.email.text", { url: window.location.href });
      return `mailto:?subject=${subject}&body=${message}`;
    }
  },

  methods: {
    shareWhatsApp() {
      const whatsAppUrl = "https://api.whatsapp.com/send?text=";
      const message = this.$t("share.whatsapp", { url: window.location.href });

      if (JQuery(window).width() < 768) {
        location.href = whatsAppUrl + message;
      } else {
        window.open(whatsAppUrl + message);
      }
    },

    changeLanguage(language) {
      this.$i18n.locale = language;
      this.$emit("languageChanged");
    },

    shareFacebook() {
      window.open("https://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location.href), "facebookwindow", "width=626,height=436, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
    },

    shareTwitter: function () {
      const message = this.$t("share.twitter", { url: window.location.href });
      window.open(`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(message)}&lang=${this.$i18n.locale}&`, "twitterwindow", "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
    },

    shareLinkedin: function () {
      const url = window.location.href;

      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`, "liwindow", "height=550, width=505, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
    }
  }
};
</script>

<style lang="scss">
  @import '@/styles/language_switch.scss';

  header {
    position: fixed;
    z-index: 100;
    background: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    line-height: 60px;
    height: 60px;
    top: 0;
    left: 0;

    .share-items {
      span {
        margin: 0 0.5rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }

      + .eo__languages {
        padding-left: 1rem;
        border-left: 1px solid var(--border-color);
      }
    }

    .language-switch {
      margin-left: 0.75rem;

      .flag-wrapper {
        width: 30px;
        height: 30px;
        overflow: hidden;
        position: relative;
        border-radius: 100%;
        display: block;

        img {
          position: absolute;
          height: 30px;

          top: 50%;
          left: 50%;

          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        .language-dropdown {
          display: block;
        }
      }

      .language-dropdown {
        position: absolute;
        display: none;
        top: 45px;

        img {
          display: block;
          cursor: pointer;
        }

        span {
          margin-top: 4px;
          display: block;
        }
      }
    }
  }
</style>
