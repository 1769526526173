<template>
  <section class="position-relative" name="carousel">
    <div class="boxes-background">
      <div class="boxes">
        <Box :settings="{ color: 'orange', size: '2', rotate: '-10' }" />
        <Box :settings="{ color: 'blue', size: '4', rotate: '-40' }" />
        <Box :settings="{ color: 'blue', size: '3', rotate: '-45' }" />
        <Box :settings="{ color: 'orange', size: '4', rotate: '-55' }" />
      </div>
    </div>

    <div id="headerCarrousel" class="carousel slide" :class="bannerClass" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img :src="image" class="d-block" alt="">
        </div>
      </div>
    </div>

    <div v-if="!hideContent" class="col-12 col-sm-8 banner-content position-absolute start-50 translate-middle-x" :class="{ withButtons: showTabs }">
      <h1 v-if="title" class="notranslate">{{ title }}</h1>
      <img v-else :src="require('@/assets/images/gogogiving.svg')" class="white-logo" />
      <p>{{ content }}</p>
    </div>

    <HeaderTabs :showTabs="showTabs" />
  </section>
</template>

<script>
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from "bootstrap";
import Box from "@/components/Box";
import HeaderTabs from "@/components/HeaderTabs";

export default {
  name: "Carrousel",

  components: {
    Box,
    HeaderTabs
  },

  props: {
    title: { type: String },
    content: { type: String },
    hideContent: { type: Boolean },
    image: { type: String },
    extraClass: { type: String },
    showTabs: { type: Boolean }
  },

  computed: {
    bannerClass() {
      const classes = [];
      if (this.extraClass) {
        classes.push(this.extraClass);
      }

      if (this.showTabs) {
        classes.push("with-tabs");
      }

      return classes;
    }
  },

  mounted() {
    this.createCarrousel();
  },

  methods: {
    createCarrousel() {
      var myCarousel = document.querySelector("#headerCarrousel");
      /* eslint-disable no-new */
      new Bootstrap.Carousel(myCarousel);
    }
  }
};
</script>

<style lang="scss">
  #headerCarrousel {
    --banner-height: 60vh;
    margin-top: 45px;

    overflow: hidden;
    background: -webkit-linear-gradient(left, var(--base-gradient-start) 0%,var(--base-gradient-center) 50%,var(--base-gradient-end) 100%);

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
    }

    &.smallImage {
      --banner-height: 40vh;
      &.with-tabs {
        --banner-height: 50vh;
      }
    }

    &,
    .carousel-inner,
    .carousel-item {
      height: var(--banner-height);
    }

    img {
      min-height: 100%;
      min-width: 100%;

      opacity: 0.35;
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }

  .banner-content {
    color: white;
    right: auto;
    bottom: auto;
    position: relative;
    z-index: 2;

    @media (min-width: 768px) {
      max-width: 60vw;
    }

    @media (max-width: 768px) {
      max-width: 90vw;
    }

    &.withButtons {
      @media (min-width: 768px) {
        bottom: 38%;
      }

      @media (max-width: 768px) {
        bottom: 25%;
      }
    }

    &:not(.withButtons) {
      bottom: 20%;
    }

    h1 {
      color: white;

      @media (min-width: 768px) {
        font-size: 5rem;
      }

      @media (max-width: 768px) {
        font-size: 2.25rem;
      }
    }

    .white-logo {
      filter: brightness(0) invert(1);
      max-height: 125px;
    }

    p {
      font-weight: 300;

      @media (min-width: 768px) and (min-height: 450px) {
        font-size: 2.4rem;
        line-height: 3.1rem;
      }

      @media (max-width: 768px) {
        line-height: 2rem;
        font-size: 1.25rem;
      }
    }
  }

  .tab-like {
    position: absolute;
    bottom: 3rem;
    left: 50%;

    transform: translateX(-50%);

    .router-link-exact-active {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  section[name="carousel"] {
    .boxes-background {
      .boxes {
        .box {
          &:nth-child(1) {
            top: 16%;
            left: 42%;
          }

          &:nth-child(2) {
            top: 45%;
            left: 14%;
          }

          &:nth-child(3) {
            top: 55%;
            right: 20%;
          }

          &:nth-child(4) {
            top: 92%;
            left: 60%;
          }
        }
      }
    }
  }
</style>
