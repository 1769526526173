<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link :to="{ name: 'Home' }" class="notranslate">Home</router-link>
      </li>
      <li class="breadcrumb-item active notranslate" aria-current="page">{{ current }}</li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    current: { type: String }
  }
};
</script>

<style scoped lang="scss">
  .breadcrumb {
    margin-bottom: 2rem;
    font-size: 0.6rem;
    text-transform: uppercase;

    .breadcrumb-item {
      color: var(--text-color);
      a {
        color: var(--base-color);
        text-decoration: none;
      }

      + .breadcrumb-item::before {
        content: ">";
      }
    }
  }
</style>
