<template>
  <div v-if="showTabs" class="tab-like" @touchstart="toggleTabs($event)">
    <router-link :to="{ name: 'iRaiserGo' }" class="primary-btn dark w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>iRaiser Go</span>
      <span class="hover-state position-absolute"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
    </router-link>

    <router-link :to="{ name: 'CFP' }" class="primary-btn dark w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-chess-queen no-background me-2 ms-0"></i>Community Fundraising Platform (CFP)</span>
      <span class="hover-state position-absolute"><i class="fas fa-chess-queen no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
    </router-link>

    <router-link :to="{ name: 'Digicollect' }" class="primary-btn dark w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-hand-holding-heart no-background me-2 ms-0"></i>Digicollect</span>
      <span class="hover-state position-absolute"><i class="fas fa-hand-holding-heart no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
    </router-link>

    <router-link :to="{ name: 'CFPForms' }" class="primary-btn dark w-auto d-lg-inline-block">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-file-alt no-background me-2 ms-0"></i>CFP Forms</span>
      <span class="hover-state position-absolute"><i class="fas fa-file-alt no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
    </router-link>

    <router-link :to="{ name: 'iRaiser' }" class="primary-btn dark w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate">
        <img :src="require('@/assets/images/caret-icon-white.svg')" width="15" alt="" />iRaiser
      </span>
      <span class="hover-state position-absolute"><img :src="require('@/assets/images/caret-icon-white.svg')" width="15" alt="" />{{ $t("dictionary.know_more") }}</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "HeaderTabs",

  props: {
    showTabs: { type: Boolean }
  },

  methods: {
    toggleTabs(event) {
      if (event.target.classList.contains("tab-like")) {
        if (event.currentTarget.classList.contains("open")) {
          event.currentTarget.classList.remove("open");
        } else {
          event.currentTarget.classList.add("open");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .tab-like {
    text-align: center;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);

    img {
      margin-right: 8px;
      filter: brightness(0) invert(1);
    }

    @media (min-width: 1000px) {
      width: 100%;
    }

    @media (min-width: 1200px) {
      width: 80%;
    }

    @media (min-width: 999px) {
      a {
        width: 17% !important;
      }
    }

    .router-link-exact-active {
      pointer-events: none;
      color: #E790BB;

      &,
      img {
        opacity: 0.7;
      }
    }
  }
</style>
