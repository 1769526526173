<template>
  <teleport to="head title">{{ pageTitle }}</teleport>
  <teleport to="head">
    <meta v-for="(metaItem, index) in metaData" :key="index" :name="metaItem.name" :content="metaItem.content">
  </teleport>
  <div id="app">
    <RouterView v-slot="{ Component }">
      <transition name="scale">
        <component :is="Component" />
      </transition>
    </RouterView>

    <PageFooter />

    <div class="bottom-footer notranslate">
      <div class="container">
        <svg width="240" height="30" viewBox="0 0 240 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_244_509)">
            <path d="M0 7.4043H6.41489C9.47872 7.4043 11.1839 9.50612 11.1839 11.9772C11.1839 14.4484 9.45593 16.5502 6.41489 16.5502H2.56231V21.9985H0V7.4043ZM6.06383 9.65658H2.56231V14.2979H6.06383C7.50912 14.2979 8.55775 13.3769 8.55775 11.9772C8.55775 10.5775 7.50912 9.65658 6.06383 9.65658Z" fill="#040405" />
            <path d="M12.2549 16.7052C12.2549 13.6869 14.3339 11.1702 17.685 11.1702C21.036 11.1702 23.1333 13.6869 23.1333 16.7052C23.1333 19.7234 21.0771 22.2629 17.685 22.2629C14.2929 22.2629 12.2549 19.7462 12.2549 16.7052ZM20.7442 16.7052C20.7442 14.845 19.65 13.2037 17.6804 13.2037C15.7108 13.2037 14.6394 14.845 14.6394 16.7052C14.6394 18.5654 15.7336 20.2295 17.6804 20.2295C19.6272 20.2295 20.7442 18.5882 20.7442 16.7052Z" fill="#040405" />
            <path d="M32.2161 14.2523L29.7632 21.9985H27.3559L24.0732 11.4255H26.4577L28.6918 19.1079L31.2085 11.4255H33.2237L35.7404 19.1079L37.9745 11.4255H40.359L37.0763 21.9985H34.669L32.2161 14.2523Z" fill="#040405" />
            <path d="M46.6599 11.1656C49.8331 11.1656 51.8666 13.5957 51.8666 16.9194V17.4666H43.7009C43.8742 19.0623 45.0778 20.3754 47.0702 20.3754C48.1006 20.3754 49.3225 19.9605 50.0702 19.2173L51.1189 20.7264C50.0702 21.734 48.5155 22.2584 46.8286 22.2584C43.6553 22.2584 41.2891 20.0471 41.2891 16.7006C41.2891 13.6368 43.5231 11.1656 46.6508 11.1656H46.6599ZM43.6827 15.8526H49.6371C49.5915 14.6033 48.7389 13.0532 46.6599 13.0532C44.6903 13.0532 43.7921 14.5623 43.6827 15.8526Z" fill="#040405" />
            <path d="M54.1416 11.4301H56.4395V12.9848C57.2282 11.9772 58.4957 11.1885 59.8772 11.1885V13.4636C59.6811 13.418 59.4395 13.3997 59.1568 13.3997C58.1948 13.3997 56.9045 14.0563 56.444 14.7994V21.9985H54.1462V11.4256L54.1416 11.4301Z" fill="#040405" />
            <path d="M66.4424 11.1656C69.6157 11.1656 71.6491 13.5957 71.6491 16.9194V17.4666H63.4835C63.6567 19.0623 64.8604 20.3754 66.8528 20.3754C67.8832 20.3754 69.105 19.9605 69.8528 19.2173L70.9014 20.7264C69.8528 21.734 68.2981 22.2584 66.6111 22.2584C63.4379 22.2584 61.0762 20.0471 61.0762 16.7006C61.0762 13.6368 63.3102 11.1656 66.4379 11.1656H66.4424ZM63.4652 15.8526H69.4196C69.374 14.6033 68.5215 13.0532 66.4424 13.0532C64.4728 13.0532 63.5746 14.5623 63.4652 15.8526Z" fill="#040405" />
            <path d="M81.3648 20.5578C80.5533 21.6064 79.3314 22.2629 77.9727 22.2629C75.26 22.2629 73.2676 20.2067 73.2676 16.7234C73.2676 13.2401 75.2372 11.1657 77.9727 11.1657C79.2858 11.1657 80.5123 11.7766 81.3648 12.8936V7.39972H83.6627V21.9985H81.3648V20.5532V20.5578ZM81.3648 14.6262C80.8405 13.8374 79.7235 13.2037 78.6293 13.2037C76.8147 13.2037 75.6521 14.6718 75.6521 16.728C75.6521 18.7842 76.8101 20.2295 78.6293 20.2295C79.7235 20.2295 80.8405 19.6186 81.3648 18.8298V14.6262Z" fill="#040405" />
            <path d="M92.2617 7.4043H94.5596V12.8982C95.3894 11.7812 96.6158 11.1703 97.9517 11.1703C100.664 11.1703 102.634 13.3131 102.634 16.728C102.634 20.1429 100.642 22.2675 97.9517 22.2675C96.5748 22.2675 95.3666 21.611 94.5596 20.5624V22.0076H92.2617V7.4043ZM97.2769 20.2295C99.1143 20.2295 100.277 18.7842 100.277 16.728C100.277 14.6718 99.1189 13.2037 97.2769 13.2037C96.2055 13.2037 95.0657 13.8602 94.5642 14.649V18.8298C95.0657 19.6186 96.2055 20.2295 97.2769 20.2295Z" fill="#040405" />
            <path d="M105.743 24.2553C106.464 24.2553 106.947 24.0365 107.252 23.3344L107.777 22.1307L103.445 11.4301H105.898L108.985 19.3541L112.071 11.4301H114.543L109.441 23.9043C108.72 25.7006 107.448 26.266 105.807 26.2888C105.479 26.2888 104.845 26.2249 104.517 26.1338L104.868 24.0775C105.109 24.187 105.479 24.2508 105.743 24.2508V24.2553Z" fill="#040405" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M152.859 6.71579C152.677 4.42703 152.248 2.80393 151.218 1.78266C150.196 0.752261 148.578 0.32369 146.285 0.141319C144.019 -0.0410518 141.082 0.022778 138.005 -0.00457764C134.923 0.022778 131.987 -0.0410518 129.725 0.141319C127.436 0.32369 125.813 0.752261 124.792 1.78266C123.762 2.80393 123.333 4.42247 123.151 6.71579C122.968 8.98174 123.032 11.9179 123.005 14.9954C123.032 18.0775 122.968 21.0137 123.151 23.2751C123.333 25.5638 123.762 27.1869 124.792 28.2082C125.813 29.2386 127.432 29.6672 129.725 29.8495C131.991 30.0319 134.927 29.9681 138.005 29.9954C141.087 29.9681 144.023 30.0319 146.285 29.8495C148.573 29.6672 150.196 29.2386 151.218 28.2082C152.248 27.1869 152.677 25.5684 152.859 23.2751C153.041 21.0091 152.978 18.0729 153.005 14.9954C152.978 11.9134 153.041 8.97719 152.859 6.71579ZM145.218 23.8267C144.611 24.3419 143.891 24.7842 143.262 24.3921C142.642 24.0045 142.368 23.1748 142.081 22.5456C141.269 20.772 140.572 18.9346 139.76 17.1611C138.958 15.4194 138.133 13.5 136.674 12.2188C136.172 11.7811 135.493 11.3343 134.713 11.9954C133.842 12.7431 133.414 13.8191 133.044 14.7766C132.26 16.8374 131.873 18.9255 131.289 21C130.345 19.9741 129.402 18.9483 128.458 17.9225C128.877 16.0532 129.616 14.1611 130.541 12.3556C131.503 10.4817 132.702 8.51214 134.412 7.04861C135.921 5.75834 137.791 4.99238 139.359 5.77658C140.873 6.53342 141.83 8.04709 142.637 9.42855C144.443 12.5243 145.806 15.8754 147.36 19.1216C148.067 20.6033 146.608 22.6368 145.218 23.8267Z" fill="#040405" />
            <path d="M162.484 5.07446C161.914 5.07446 161.435 5.26595 161.038 5.64893C160.646 6.03191 160.45 6.49696 160.45 7.03951C160.45 7.58206 160.646 8.03799 161.038 8.43009C161.43 8.82218 161.914 9.01823 162.484 9.01823C163.054 9.01823 163.532 8.82218 163.92 8.43009C164.307 8.03799 164.503 7.57294 164.503 7.03951C164.503 6.50607 164.307 6.03191 163.92 5.64893C163.532 5.26595 163.054 5.07446 162.484 5.07446Z" fill="#040405" />
            <path d="M164.375 10.7963H160.582V24.6702H164.375V10.7963Z" fill="#040405" />
            <path d="M180.438 15.2827C181.044 14.3298 181.35 13.2356 181.35 12V11.9727C181.35 10.7234 181.085 9.652 180.552 8.76294C180.023 7.87388 179.257 7.18999 178.263 6.71127C177.265 6.23255 176.07 5.99091 174.67 5.99091H166.956V24.6702H170.863V17.9134H173.996L177.465 24.6702H181.906L177.971 17.3161C179.006 16.9104 179.827 16.231 180.438 15.2781V15.2827ZM177.351 12.0137C177.351 12.9301 177.073 13.6459 176.521 14.1611C175.97 14.6809 175.208 14.9362 174.242 14.9362H170.863V9.05929H174.192C175.149 9.05929 175.915 9.32373 176.489 9.84805C177.064 10.3724 177.351 11.0882 177.351 11.9818V12.0091V12.0137Z" fill="#040405" />
            <path d="M192.242 11.0881C191.362 10.7052 190.322 10.5137 189.123 10.5137C187.924 10.5137 186.857 10.7006 185.973 11.0745C185.088 11.4483 184.4 11.9681 183.903 12.6337C183.406 13.2994 183.118 14.0562 183.041 14.9134L183.027 15.0684H186.497L186.524 14.9498C186.62 14.503 186.875 14.1337 187.286 13.8419C187.701 13.5547 188.261 13.4088 188.968 13.4088C189.675 13.4088 190.277 13.5866 190.664 13.9377C191.052 14.2933 191.248 14.7766 191.248 15.3875V16.2356L187.819 16.4498C186.67 16.5274 185.694 16.7371 184.887 17.0836C184.08 17.4301 183.465 17.8997 183.041 18.5015C182.617 19.1033 182.407 19.8237 182.407 20.6717V20.6991C182.407 21.5106 182.603 22.231 182.995 22.8693C183.387 23.503 183.935 24 184.641 24.3556C185.343 24.7158 186.16 24.8936 187.08 24.8936C187.682 24.8936 188.248 24.8024 188.772 24.6201C189.292 24.4377 189.757 24.1778 190.163 23.8313C190.568 23.4848 190.906 23.079 191.17 22.614H191.248V24.6702H195.027V15.155C195.027 14.2249 194.781 13.4088 194.298 12.7067C193.81 12.0091 193.126 11.4666 192.246 11.0836L192.242 11.0881ZM190.86 20.8997C190.605 21.2827 190.254 21.5836 189.811 21.807C189.369 22.0258 188.863 22.1353 188.302 22.1353C187.646 22.1353 187.126 21.9894 186.734 21.7021C186.346 21.4149 186.15 21.0137 186.15 20.503V20.4757C186.15 19.9833 186.342 19.5912 186.72 19.2994C187.099 19.0076 187.65 18.8389 188.375 18.7933L191.234 18.6018V19.6094C191.234 20.0836 191.106 20.5122 190.851 20.8951L190.86 20.8997Z" fill="#040405" />
            <path d="M201.05 10.7963H197.257V24.6702H201.05V10.7963Z" fill="#040405" />
            <path d="M199.158 5.07446C198.589 5.07446 198.11 5.26595 197.713 5.64893C197.321 6.03191 197.125 6.49696 197.125 7.03951C197.125 7.58206 197.321 8.03799 197.713 8.43009C198.105 8.82218 198.589 9.01823 199.158 9.01823C199.728 9.01823 200.207 8.82218 200.595 8.43009C200.982 8.03799 201.178 7.57294 201.178 7.03951C201.178 6.50607 200.982 6.03191 200.595 5.64893C200.207 5.26595 199.728 5.07446 199.158 5.07446Z" fill="#040405" />
            <path d="M210.999 16.6459L208.642 16.1398C208.177 16.0349 207.803 15.9164 207.529 15.7796C207.251 15.6429 207.055 15.4787 206.941 15.2963C206.823 15.1094 206.768 14.8997 206.768 14.6672V14.6535C206.768 14.3526 206.855 14.0927 207.028 13.8784C207.201 13.6641 207.447 13.4954 207.767 13.3723C208.086 13.2538 208.46 13.19 208.893 13.19C209.59 13.19 210.142 13.3541 210.543 13.6869C210.944 14.0198 211.177 14.4301 211.236 14.9225L211.25 15.0273H214.733V14.8997C214.706 14.0881 214.464 13.3495 214.004 12.6884C213.543 12.0228 212.882 11.4939 212.025 11.1018C211.168 10.7097 210.124 10.5137 208.897 10.5137C207.671 10.5137 206.7 10.6915 205.824 11.0517C204.949 11.4118 204.27 11.9134 203.791 12.5608C203.312 13.2082 203.07 13.9742 203.07 14.8632V14.8769C203.07 15.5653 203.221 16.1763 203.522 16.7097C203.823 17.2386 204.279 17.6854 204.88 18.041C205.482 18.4012 206.235 18.6748 207.133 18.8617L209.49 19.3678C210.169 19.5137 210.648 19.7006 210.912 19.924C211.181 20.1474 211.314 20.4483 211.314 20.8176V20.8313C211.314 21.1322 211.222 21.3921 211.036 21.6064C210.849 21.8207 210.589 21.9894 210.251 22.1033C209.914 22.2219 209.504 22.2766 209.02 22.2766C208.268 22.2766 207.684 22.1261 207.26 21.8252C206.836 21.5243 206.567 21.1003 206.444 20.5577L206.417 20.4392H202.729L202.742 20.5577C202.847 21.4286 203.148 22.1945 203.649 22.8556C204.151 23.5167 204.853 24.0319 205.751 24.4012C206.654 24.7705 207.744 24.9574 209.02 24.9574C210.21 24.9574 211.263 24.7705 212.171 24.3921C213.083 24.0182 213.794 23.4939 214.314 22.8191C214.833 22.1444 215.089 21.3556 215.089 20.4483V20.4346C215.089 19.4179 214.77 18.6064 214.136 18C213.502 17.3982 212.453 16.9422 210.999 16.6413V16.6459Z" fill="#040405" />
            <path d="M226.359 11.3708C225.37 10.7963 224.198 10.5091 222.844 10.5091C221.49 10.5091 220.314 10.8054 219.315 11.3936C218.317 11.9863 217.546 12.8206 216.99 13.9058C216.438 14.9909 216.16 16.2675 216.16 17.7447V17.7583C216.16 19.2355 216.438 20.5121 216.99 21.5881C217.542 22.6687 218.326 23.4985 219.347 24.0775C220.364 24.6611 221.581 24.9529 222.999 24.9529C223.993 24.9529 224.855 24.8252 225.593 24.5653C226.332 24.3054 226.957 23.9635 227.472 23.5304C227.987 23.0972 228.393 22.6231 228.694 22.1079C228.995 21.5881 229.2 21.0775 229.3 20.5668L229.341 20.4255H225.871L225.844 20.503C225.757 20.7629 225.593 21.0182 225.352 21.2644C225.11 21.5152 224.8 21.7203 224.422 21.8845C224.043 22.0486 223.592 22.1307 223.077 22.1307C222.42 22.1307 221.855 21.9848 221.381 21.6975C220.906 21.4103 220.542 20.9909 220.286 20.4483C220.049 19.9377 219.922 19.3358 219.908 18.6383H229.455V17.462C229.455 16.0623 229.186 14.845 228.648 13.8009C228.11 12.7568 227.344 11.9498 226.359 11.3754V11.3708ZM221.353 13.7599C221.805 13.4772 222.324 13.3313 222.912 13.3313C223.501 13.3313 224.016 13.4681 224.458 13.7462C224.9 14.0243 225.251 14.4346 225.502 14.9817C225.666 15.3465 225.776 15.7659 225.835 16.2447H219.958C220.017 15.7796 220.127 15.3693 220.295 15.0091C220.551 14.462 220.902 14.0471 221.358 13.7599H221.353Z" fill="#040405" />
            <path d="M238.747 10.5501C238.555 10.5228 238.359 10.5091 238.15 10.5091C237.338 10.5091 236.663 10.7462 236.116 11.2203C235.574 11.6945 235.195 12.3602 234.99 13.2127H234.912V10.7918H231.133V24.6656H234.912V16.9012C234.912 16.2264 235.031 15.6565 235.264 15.1915C235.496 14.7264 235.833 14.3708 236.271 14.1292C236.713 13.8875 237.242 13.769 237.862 13.769C238.14 13.769 238.396 13.7872 238.637 13.8282C238.879 13.8693 239.08 13.9194 239.235 13.9878V10.6504C239.098 10.6094 238.934 10.5729 238.742 10.5456L238.747 10.5501Z" fill="#040405" />
          </g>
          <defs>
            <clipPath id="clip0_244_509">
              <rect width="239.239" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import PageFooter from "@/components/Footer.vue";
import { pageTitle, metaData } from "./store/metaStore";

export default {
  name: "App",

  components: {
    PageFooter
  },

  setup() {
    return { pageTitle, metaData };
  }
};
</script>

<style lang="scss">
  @import '@/styles/variables.scss';
  @import '@/styles/globals.scss';
  @import '@/styles/responsive.scss';
  @import '@/styles/form.scss';

  #app {
    width: 100%;
    min-height: 100vh;
  }

  body {
    color: var(--text-color);
  }

  .primary-btn {
    cursor: pointer;
  }

  .bottom-footer {
    background: -webkit-linear-gradient(left, #343434 0%, #2C2B2B);
    color: white;
    font-size: 0.825rem;
    padding: 2rem 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;

        path {
          fill: white;
        }
      }
    }
  }
</style>
