<template>
  <div class="about">
    <PageHeader />
    <Carrousel :title="$t('banner.kentaa.title')" :content="$t('banner.kentaa.content')" :showTabs="true" :extraClass="'smallImage'" :image="require(`@/assets/images/gogogiving-banner.webp`)" />
    <article>
      <div class="container pb-5">
        <Breadcrumbs :current="$t('breadcrumbs.about_iraiser')" />
        <div v-html="$t('content_pages.kentaa')"></div>
        <a href="https://www.iraiser.com" target="_blank" class="primary-btn pink d-inline-block min-width w-auto mt-3">
          <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{ $t('buttons.more_info') }}</span>
          <span class="hover-state position-absolute"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
        </a>
      </div>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Carrousel from "@/components/Carrousel.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "About",

  components: {
    Breadcrumbs,
    Carrousel,
    PageHeader
  },

  created() {
    this.$setTitle(this.$t("seo.kentaa.title"));
    this.$setMeta([
      { name: "description", content: "Digicollect ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
      { property: "og:title", content: "Opengraph titel" },
      { property: "og:description", content: "Opengraph  beschrijving" },
      { property: "og:site_name", content: "GogoGiving" },
      { property: "og:url", content: window.location.href },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" }
    ]);
  }
};
</script>
