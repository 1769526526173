<template>
  <footer>
    <div class="front-layer"></div>
    <div class="boxes position-absolute start-0">
      <Box :settings="{ color: 'blue', size: '8', rotate: '-34' }" />
      <Box :settings="{ color: 'blue', size: '2', rotate: '18' }" />
      <Box :settings="{ color: 'orange', size: '1', rotate: '45' }" />
      <Box :settings="{ color: 'blue', size: '2', rotate: '135' }" />
      <Box :settings="{ color: 'orange', size: '2', rotate: '145' }" />
      <Box :settings="{ color: 'blue', size: '4', rotate: '-45' }" />
      <Box :settings="{ color: 'orange', size: '2', rotate: '10' }" />
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 mb-2 mb-sm-0">
          <svg
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 200 55"
            style="enable-background:new 0 0 200 55;"
            xml:space="preserve">
            <g>
              <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="1.8" width="51.6" height="51.4">
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>

              <mask id="mask0_201_70_00000171713977650932320220000015542414531630576543_" maskUnits="userSpaceOnUse" x="0" y="1.8" width="51.6" height="51.4">
                <path class="st0" d="M0,1.8h51.6v51.4H0V1.8z" />
              </mask>
              <g class="st1">
                <path
                  class="st2"
                  d="M38.2,42.6c-1,0.9-2.3,1.6-3.4,1c-1.1-0.7-1.5-2.1-2-3.2c-1.4-3-2.6-6.2-4-9.2c-1.4-3-2.8-6.3-5.3-8.5
			c-0.9-0.8-2-1.5-3.4-0.4c-1.5,1.3-2.2,3.1-2.9,4.8c-1.4,3.5-2,7.1-3,10.7c-1.6-1.8-3.2-3.5-4.9-5.3c0.7-3.2,2-6.4,3.6-9.5
			c1.7-3.2,3.7-6.6,6.7-9.1c2.6-2.2,5.8-3.5,8.5-2.2c2.6,1.3,4.2,3.9,5.6,6.3c3.1,5.3,5.5,11,8.1,16.6
			C43.1,37.1,40.6,40.6,38.2,42.6z M51.4,13.3c-0.3-3.9-1.1-6.7-2.8-8.4C46.8,3.1,44,2.3,40.1,2c-3.9-0.3-8.9-0.2-14.2-0.2
			c-5.3,0-10.4-0.1-14.2,0.2c-3.9,0.3-6.7,1-8.5,2.8c-1.8,1.7-2.5,4.5-2.8,8.4C-0.1,17.2,0,22.2,0,27.5c0,5.3-0.1,10.3,0.2,14.2
			c0.3,3.9,1.1,6.7,2.8,8.4c1.8,1.8,4.5,2.5,8.5,2.8c3.9,0.3,8.9,0.2,14.2,0.2c5.3,0,10.4,0.1,14.2-0.2c3.9-0.3,6.7-1,8.5-2.8
			c1.8-1.7,2.5-4.5,2.8-8.4c0.3-3.9,0.2-8.9,0.2-14.2C51.6,22.2,51.7,17.2,51.4,13.3z" />
              </g>
              <path
                class="st2"
                d="M67.9,10.5c-1,0-1.8,0.3-2.5,1c-0.7,0.7-1,1.5-1,2.4c0,0.9,0.3,1.7,1,2.4c0.7,0.7,1.5,1,2.5,1
		c1,0,1.8-0.3,2.5-1c0.7-0.7,1-1.5,1-2.4c0-0.9-0.3-1.7-1-2.4C69.7,10.8,68.9,10.5,67.9,10.5z" />
              <defs>

                <filter id="Adobe_OpacityMaskFilter_00000152963710163906172910000010671743016113517759_" filterUnits="userSpaceOnUse" x="64.7" y="10.5" width="135.3" height="34.1">
                  <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                </filter>
              </defs>

              <mask id="mask1_201_70_00000091736280502546914870000017081436367068745874_" maskUnits="userSpaceOnUse" x="64.7" y="10.5" width="135.3" height="34.1">

                <path
                  style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;filter:url(#Adobe_OpacityMaskFilter_00000152963710163906172910000010671743016113517759_);"
                  d="
			M0,53.2h200V1.8H0V53.2z" />
              </mask>
              <g class="st4">
                <path class="st2" d="M64.7,44.1h6.5V20.3h-6.5V44.1z" />
                <path
                  class="st2"
                  d="M93.5,22.4c0,1.6-0.5,2.8-1.4,3.7c-1,0.9-2.3,1.3-3.9,1.3h-5.8V17.3h5.7c1.6,0,3,0.5,4,1.4
			C93,19.6,93.5,20.8,93.5,22.4L93.5,22.4z M98.8,28c1-1.6,1.6-3.5,1.6-5.6v0c0-2.1-0.5-4-1.4-5.5c-0.9-1.5-2.2-2.7-3.9-3.5
			c-1.7-0.8-3.8-1.2-6.2-1.2H75.6v32h6.7V32.5h5.1h0.2h0.2l6,11.6h7.6l-6.8-12.6C96.4,30.8,97.8,29.6,98.8,28z" />
                <path
                  class="st2"
                  d="M116.8,37.6c-0.4,0.7-1,1.2-1.8,1.6c-0.8,0.4-1.6,0.6-2.6,0.6c-1.1,0-2-0.2-2.7-0.7c-0.7-0.5-1-1.2-1-2.1v0
			c0-0.8,0.3-1.5,1-2c0.7-0.5,1.6-0.8,2.9-0.9l4.9-0.3v1.7C117.4,36.2,117.2,36.9,116.8,37.6z M119.1,20.8c-1.5-0.7-3.3-1-5.4-1
			c-2.1,0-3.9,0.3-5.4,1c-1.5,0.6-2.7,1.5-3.6,2.7c-0.9,1.1-1.3,2.4-1.5,3.9l0,0.3h6l0-0.2c0.2-0.8,0.6-1.4,1.3-1.9
			c0.7-0.5,1.7-0.7,2.9-0.7c1.3,0,2.2,0.3,2.9,0.9c0.7,0.6,1,1.4,1,2.5v1.5l-5.9,0.4c-2,0.1-3.7,0.5-5,1.1c-1.4,0.6-2.4,1.4-3.2,2.4
			c-0.7,1-1.1,2.3-1.1,3.7v0c0,1.4,0.3,2.6,1,3.7c0.7,1.1,1.6,1.9,2.8,2.6c1.2,0.6,2.6,0.9,4.2,0.9c1,0,2-0.2,2.9-0.5
			c0.9-0.3,1.7-0.8,2.4-1.4c0.7-0.6,1.3-1.3,1.7-2.1h0.1v3.5h6.5V27.8c0-1.6-0.4-3-1.3-4.2C121.8,22.4,120.6,21.4,119.1,20.8z" />
                <path class="st2" d="M127.8,44.1h6.5V20.3h-6.5V44.1z" />
                <path
                  class="st2"
                  d="M131,10.5c-1,0-1.8,0.3-2.5,1c-0.7,0.7-1,1.5-1,2.4c0,0.9,0.3,1.7,1,2.4c0.7,0.7,1.5,1,2.5,1s1.8-0.3,2.5-1
			c0.7-0.7,1-1.5,1-2.4c0-0.9-0.3-1.7-1-2.4C132.8,10.8,132,10.5,131,10.5z" />
                <path
                  class="st2"
                  d="M151.4,30.3l-4.1-0.9c-0.8-0.2-1.4-0.4-1.9-0.6c-0.5-0.2-0.8-0.5-1-0.8c-0.2-0.3-0.3-0.7-0.3-1.1v0
			c0-0.5,0.1-1,0.4-1.3c0.3-0.4,0.7-0.7,1.3-0.9c0.5-0.2,1.2-0.3,1.9-0.3c1.2,0,2.1,0.3,2.8,0.9c0.7,0.6,1.1,1.3,1.2,2.1l0,0.2h6
			v-0.2c0-1.4-0.5-2.7-1.3-3.8c-0.8-1.1-1.9-2-3.4-2.7c-1.5-0.7-3.3-1-5.4-1c-2,0-3.8,0.3-5.3,0.9c-1.5,0.6-2.7,1.5-3.5,2.6
			c-0.8,1.1-1.2,2.4-1.2,3.9v0c0,1.2,0.3,2.2,0.8,3.1s1.3,1.7,2.3,2.3c1,0.6,2.3,1.1,3.9,1.4l4.1,0.9c1.2,0.3,2,0.6,2.4,1
			c0.5,0.4,0.7,0.9,0.7,1.5v0c0,0.5-0.2,1-0.5,1.3c-0.3,0.4-0.8,0.7-1.3,0.9c-0.6,0.2-1.3,0.3-2.1,0.3c-1.3,0-2.3-0.3-3-0.8
			c-0.7-0.5-1.2-1.2-1.4-2.2l0-0.2h-6.3l0,0.2c0.2,1.5,0.7,2.8,1.6,3.9c0.9,1.1,2.1,2,3.6,2.7c1.6,0.6,3.4,1,5.6,1
			c2,0,3.9-0.3,5.4-1c1.6-0.6,2.8-1.5,3.7-2.7c0.9-1.2,1.3-2.5,1.3-4.1v0c0-1.7-0.5-3.1-1.6-4.2C155.7,31.6,153.9,30.8,151.4,30.3z"
                />
                <path
                  class="st2"
                  d="M169.2,25.4c0.8-0.5,1.7-0.7,2.7-0.7c1,0,1.9,0.2,2.7,0.7c0.8,0.5,1.4,1.2,1.8,2.1c0.3,0.6,0.5,1.3,0.6,2.2
			h-10.1c0.1-0.8,0.3-1.5,0.6-2.1C167.8,26.6,168.4,25.8,169.2,25.4z M177.8,21.3c-1.7-1-3.7-1.5-6-1.5c-2.3,0-4.4,0.5-6.1,1.5
			c-1.7,1-3,2.4-4,4.3c-1,1.9-1.4,4-1.4,6.6v0c0,2.5,0.5,4.7,1.4,6.6c1,1.8,2.3,3.3,4.1,4.3c1.8,1,3.8,1.5,6.3,1.5
			c1.7,0,3.2-0.2,4.5-0.7c1.3-0.4,2.3-1,3.2-1.8c0.9-0.7,1.6-1.6,2.1-2.4c0.5-0.9,0.9-1.8,1-2.6l0.1-0.2h-6l0,0.1
			c-0.1,0.4-0.4,0.9-0.8,1.3c-0.4,0.4-1,0.8-1.6,1.1c-0.7,0.3-1.4,0.4-2.3,0.4c-1.1,0-2.1-0.2-2.9-0.7c-0.8-0.5-1.4-1.2-1.9-2.1
			c-0.4-0.9-0.6-1.9-0.7-3.1h16.4v-2c0-2.4-0.5-4.5-1.4-6.3C180.9,23.6,179.5,22.2,177.8,21.3z" />
                <path
                  class="st2"
                  d="M198.1,19.8c-1.4,0-2.6,0.4-3.5,1.2c-0.9,0.8-1.6,2-1.9,3.4h-0.1v-4.1h-6.5v23.8h6.5V30.7
			c0-1.2,0.2-2.1,0.6-2.9c0.4-0.8,1-1.4,1.7-1.8c0.8-0.4,1.7-0.6,2.7-0.6c0.5,0,0.9,0,1.3,0.1c0.4,0.1,0.8,0.2,1,0.3V20
			c-0.2-0.1-0.5-0.1-0.8-0.2C198.8,19.8,198.5,19.8,198.1,19.8z" />
              </g>
            </g>
          </svg>
        </div>

        <div class="col-12 col-md-3 mt-4 my-sm-0">
          <ul>
            <li><router-link :to="{ name: 'iRaiserGo' }" class="notranslate"><i class="fas fa-angle-double-right"></i>iRaiser Go</router-link></li>
            <li><router-link :to="{ name: 'CFP' }" class="notranslate"><i class="fas fa-chess-queen"></i>Community Fundraising Platform (CFP)</router-link></li>
            <li><router-link :to="{ name: 'Digicollect' }" class="notranslate"><i class="fas fa-hand-holding-heart"></i>Digicollect</router-link></li>
            <li><router-link :to="{ name: 'CFPForms' }" class="notranslate"><i class="fas fa-file-alt"></i>CFP Forms</router-link></li>
            <li><router-link :to="{ name: 'iRaiser' }" class="notranslate"><img :src="require('@/assets/images/caret-icon-white.svg')" width="15" alt="" />{{ $t("footer.about_kentaa") }}</router-link></li>
          </ul>
        </div>

        <div class="col-12 col-md-5 mt-4 my-sm-0">
          <h4>{{ $t("footer.about.title") }}</h4>
          <p>{{ $t("footer.about.content") }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Box from "@/components/Box.vue";

export default {
  name: "PageFooter",

  components: {
    Box
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/footer.scss';

.boxes {
  height: 100%;
  width: 100%;
  bottom: 0;

  .box {
    position: absolute;
    &:nth-child(1) {
      top: 41%;
      left: -68px;
    }

    &:nth-child(2) {
      top: 45%;
      left: 25%;
    }

    &:nth-child(3) {
      top: 16%;
      left: 30%;
    }

    &:nth-child(4) {
      bottom: 16%;
      left: 50%;
    }

    &:nth-child(5) {
      top: -9%;
      right: 18%;
    }

    &:nth-child(6) {
      top: 12%;
      right: 8%;
    }

    &:nth-child(7) {
      bottom: 19%;
      right: 1%;
    }
  }
}

</style>
