<template>
  <div :class="{'col-12 col-md-6 col-lg-4 mt-4': !hideColClass}">
    <div class="showcase base-rounded h-100 position-relative overflow-hidden" :data-category="showcase.category">
      <router-link :to="{ name: 'Detail', params: { slug: slug, id: showcase.id }}">
        <div class="image">
          <picture v-if="showcase.banner" class="d-block">
            <source
              :srcset="showcase.image"
              class="d-block w-auto"
              media="(min-width: 800px)">
            <img :src="showcase.banner" class="d-block" alt="" />
          </picture>
          <picture v-else class="d-block">
            <source
              :srcset="require('@/assets/images/gogogiving-banner-600.webp')"
              class="d-block"
              media="(min-width: 800px)">
            <img :src="require('@/assets/images/gogogiving-banner-600.webp')" class="d-block" alt="" />
          </picture>
        </div>
      </router-link>

      <div class="content px-4 pt-4">
        <span class="name small-font d-block" v-html="showcase.name"></span>
        <router-link :to="{ name: 'Detail', params: { slug: slug, id: showcase.id }}">
          <h3 class="mt-1 mb-3 ellipse-3" v-html="showcase.title"></h3>
        </router-link>

        <DonationProgress :data="showcase" />

        <p class="ellipse-4 mt-3" v-html="strippedContent(showcase.description)"></p>

        <div class="row align-items-center position-absolute bottom">
          <div class="col-5 col-md-5 col-lg-5 col-xl-6">
            <img height="50" class="logo" :src="showcase.logo" />
          </div>

          <div class="col-7 col-md-7 col-lg-7 col-xl-6">
            <router-link :to="{ name: 'Detail', params: { slug: slug, id: showcase.id }}" class="d-block primary-btn">
              <span class="initial-state w-100 d-inline-block">{{ $t("buttons.read_more") }} <i class="fa fa-arrow-right"></i></span>
              <span class="hover-state position-absolute">{{ $t("dictionary.here_we_go") }} <i class="fa fa-arrow-right"></i></span>
            </router-link>
          </div>
        </div>
      </div>

      <router-link :to="{ name: routeName }" class="platform d-block small-font text-center border-top default-gradient">
        <span>{{ showcase.platform }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import DonationProgress from "@/components/DonationProgress.vue";

export default {
  name: "Showcase",

  components: {
    DonationProgress
  },

  props: {
    hideColClass: { type: Boolean },
    showcase: { type: Object }
  },

  computed: {
    routeName: function () {
      return this.showcase.platform.replace(" ", "");
    },

    slug: function () {
      const end = `-${this.showcase.platform.toLowerCase().split(" ").join("")}-${this.showcase.id}`;
      return this.showcase.name.split(" ").join("-").toLowerCase() + end;
    }
  },

  methods: {
    strippedContent(string) {
      return string.replace(/(<([^>]+)>)/gi, "");
    }
  }
};
</script>

<style scoped lang="scss">
.showcase {
  --platform-label-height: 60px;
  --platform-label-offset: 20px;
  padding-bottom: calc(var(--platform-label-height) - var(--platform-label-offset));
  box-shadow: 0px 5px 10px #00000033;
  z-index: 4;

  a:hover {
    .image {
      img {
        transform: scale(1.05);
      }
    }

    h3 {
      opacity: 0.8;
    }
  }

  .image {
    --height: 180px;

    overflow: hidden;
    position: relative;
    height: var(--height);

    picture {
      height: 100%;
    }

    img {
      transition: transform ease-in-out 0.3s;
      width: 100%;
      object-fit: cover;
      min-height: 100%;
    }
  }

  h3 {
    transition: opacity ease-in-out 0.3s;
    height: calc(var(--body-line-height) * 2);
  }

  a {
    text-decoration: none;
  }

  .name {
    color: var(--base-color);
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .logo {
    max-width: 100%;
    height: auto !important;
    max-height: 52px;
  }

  .content {
    position: relative;
    padding-bottom: 80px;
    background: white;
    min-height: 350px;
    z-index: 1;

    .ellipse-4 {
      height: calc(var(--body-line-height) * 4);
    }
  }

  .content,
  .platform {
    border-bottom-left-radius: var(--large-border-radius);
    border-bottom-right-radius: var(--large-border-radius);

    .bottom {
      width: 100%;
      padding: 0 1.5rem;
      bottom: 30px;
      left: 0.75rem;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
  }

  .platform {
    color: white;

    line-height: calc(var(--platform-label-height) + var(--platform-label-offset));
    height: var(--platform-label-height);
    text-decoration: none;
    z-index: 0;

    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    span {
      position: relative;

      &:after {
        transition: all ease-in-out 0.2s;
        content: "";
        height: 1px;
        background: white;
        position: absolute;

        bottom: -1px;
        left: 0;
        width: 100%;
      }
    }

    &:hover {
      text-decoration: none;

      span {
        &:after {
          width: 0;
        }
      }
    }
  }
}
</style>
