import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import { createWebHistory, createRouter } from "vue-router";
import { createI18n } from "vue-i18n";
import { setPageTitle, setMetaData } from "./store/metaStore";

import Home from "./views/Home.vue";
import iRaiser from "./views/iRaiser.vue";
import KentaaGo from "./views/KentaaGo.vue";
import CFP from "./views/KentaaPremium.vue";
import Digicollect from "./views/Digicollect.vue";
import CFPForms from "./views/KentaaForms.vue";

import en from "./locales/en.json";
import de from "./locales/de.json";
import nl from "./locales/nl.json";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  }, {
    path: "/about-iraiser",
    name: "iRaiser",
    component: iRaiser
  }, {
    path: "/iraiser-go",
    name: "iRaiserGo",
    component: KentaaGo
  }, {
    path: "/cfp",
    name: "CFP",
    component: CFP
  }, {
    path: "/digicollect",
    name: "Digicollect",
    component: Digicollect
  }, {
    path: "/cfp-forms",
    name: "CFPForms",
    component: CFPForms
  }, {
    path: "/:slug",
    name: "Detail",
    component: () => import(/* webpackChunkName: "iRaiser" */ "./views/Detail.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    de: de,
    en: en,
    nl: nl
  }
});

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);
app.config.globalProperties.$setTitle = setPageTitle;
app.config.globalProperties.$setMeta = setMetaData;

app.use(router);
app.use(i18n);
app.use(VueAxios, axios);
app.use(VueGtag, {
  config: {
    id: "G-J1MPLMSZ8Y",
    params: {
      send_page_view: true
    }
  },
  appName: "GoGoGiving",
  pageTrackerScreenviewEnabled: true
});

app.mount("#app");
